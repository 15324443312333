//Base typography for the stylesheet
body {
  color: $black;
  font-family: $sans-serif-font-family;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
}

p {
  margin: 0 0 $md-pad;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  margin: $lg-pad 0 $md-pad;
}

h1 {
  border-bottom: $border $trans-light;
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 500;
}

h2 {
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
}

h3 {
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 500;
  margin: $md-pad 0 $xs-pad;
}

h4 {
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 600;
  margin: $md-pad 0 $xs-pad;
}

h5 {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  margin: $md-pad 0 $xs-pad;
}

h6 {
  color: $grey;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  margin: $md-pad 0 $xs-pad;
}

small {
  color: $grey;
}

pre {
  background: $light-grey;
  color: $black;
  display: block;
  font-family: $monospace-font-family;
  font-size: 1.4rem;
  margin: $md-pad 0;
  padding: $md-pad;
  word-break: break-all;
  word-wrap: break-word;
}

code {
  background: $light-grey;
  color: $black;
  font-family: $monospace-font-family;
  font-size: 1.4rem;
  word-break: break-all;
  word-wrap: break-word;
}

a {
  color: $primary-color;
  transition: $transition;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

dl {
  margin-bottom: $md-pad;
}

dd {
  margin-left: $xlg-pad;
}

ul,
ol {
  margin-bottom: $sm-pad;
  padding-left: $lg-pad;
}

blockquote {
  border-left: .2rem solid $primary-color;
  font-family: $serif-font-family;
  font-style: italic;
  margin: $md-pad 0;
  padding-left: $md-pad;
}

figcaption {
  font-family: $serif-font-family;
}
